<template>
  <div>
    <HeaderPanel
      title="User"
      :filter="filter"
      placeholder="Search Name"
      @search="getAdmin"
      @sidebar="sidebarFilter"
      :hasDropdown="false"
      routerPath="/user-management/detail/0"
    />

    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <b-row>
        <b-col>
          <b-table
            responsive
            striped
            hover
            :fields="fields"
            :items="items"
            :busy="isBusy"
            show-empty
            empty-text="No matching records found"
          >
            <template v-slot:cell(sales_no)="data">
              <div>{{ data.item.sales_no || "Admin" }}</div>
            </template>
            <template v-slot:cell(active)="data">
              <div
                :class="[
                  data.item.active == 1 ? 'text-success' : 'text-warning',
                ]"
              >
                {{ data.item.active == 1 ? "Active" : "In Active" }}
              </div>
            </template>
            <template v-slot:cell(action)="data">
              <font-awesome-icon
                icon="pencil-alt"
                class="text-warning"
                @click="
                  $router.push('/user-management/detail/' + data.item.user_guid)
                "
              />
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <Pagination
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
        @handleChangeTake="handleChangeTake"
      />
    </div>

    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      :placeholder="'Search Name'"
      @searchAll="getAdmin"
      @clearFilter="clearFilter"
    >
      <template v-slot:filter-option>
        <b-form-group>
          <template #label>
            <b>Role:</b><br />
            <b-form-checkbox
              v-model="RoleSelectedAll"
              aria-describedby="flavours"
              aria-controls="flavours"
              @change="RoleToggleAll"
            >
              All
            </b-form-checkbox>
          </template>

          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              v-model="filter.role"
              :options="Role"
              textField="name"
              valueField="id"
              :aria-describedby="ariaDescribedby"
              class="ml-4"
              stacked
            ></b-form-checkbox-group>
          </template>
        </b-form-group>
      </template>
    </SideBarFilter>
    <router-view></router-view>
  </div>
</template>
<script>
import HeaderPanel from "@/components/HeaderPanel";

import SideBarFilter from "@/components/SideBarFilter";

export default {
  name: "admin",
  components: {
    HeaderPanel,
    SideBarFilter,
  },
  data() {
    return {
      Role: [{ name: "Shopee", id: 1 }],
      RoleSelected: [],
      RoleSelectedAll: false,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        page: 0,
        take: 100,
        search: "",
        status: [],
      },
      isBusy: false,
      rows: 0,
      showing: 1,
      showingTo: 10,
      fields: [
        {
          key: "sales_no",
          label: "Staff ID.",
        },
        {
          key: "displayName",
          label: "Name",
        },
        {
          key: "role_name",
          label: "Role",
          class: "text-center",
        },
        {
          key: "active",
          label: "Status",
        },
        { key: "action", label: "" },
      ],
      items: [],
    };
  },
  created() {
    this.getAdmin();
    // this.getRole();
  },
  watch: {
    "filter.role"(newValue) {
      // Handle changes in individual flavour checkboxes
      if (newValue.length === 0) {
        this.RoleSelectedAll = false;
      } else if (newValue.length === this.Role.length) {
        this.RoleSelectedAll = true;
      } else {
        this.RoleSelectedAll = false;
      }
    },
  },
  methods: {
    RoleToggleAll(checked) {
      this.filter.role = checked ? this.Role.map((el) => el.id).slice() : [];
    },
    async getRole() {
      const { data } = await this.axios(`/Admin/getFilterList`);
      this.Role = data.detail;
    },
    async getAdmin() {
      this.isBusy = true;
      const response = await this.axios.post(`/user/list`, this.filter);
      this.isBusy = false;
      this.items = response.data.detail.data;
      this.rows = response.data.detail.total;
    },

    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    pagination(page) {
      this.filter.page = page;
      this.getAdmin();
    },
    handleChangeTake(val) {
      this.filter.take = val;
      this.filter.page = 1;
      this.getAdmin();
    },

    clearFilter() {
      this.filter = {
        search: "",
        status: [],
        role: [],
      };
    },
  },
};
</script>
